/* eslint-disable max-len */
const _BASE_ROUTE = ''
const _INDEX_ROUTE = '/'
const _LOGIN_ROUTE = '/login'
const _SIGNUP_ROUTE = '/sign-up'
const _SIGNUP_CONFIRM_EMAIL_ROUTE = `${_SIGNUP_ROUTE}/confirm-email`
const _SIGNUP_GET_STARTED_ROUTE = `${_SIGNUP_ROUTE}/get-started`
const _FORGOT_PASSWORD_ROUTE = '/forgot-password'
const _UPDATE_PASSWORD_ROUTE = '/update-password'

const _SETTINGS_BASE = `${_BASE_ROUTE}/settings`
const _SETTINGS_LANGUAGE = `${_SETTINGS_BASE}/language`
const _SETTINGS_ACCOUNT = `${_SETTINGS_BASE}/account`
const _SETTINGS_SUBSCRIPTION = `${_SETTINGS_BASE}/subscription`
const _SETTINGS_INTEGRATIONS = `${_SETTINGS_BASE}/integrations`

const _CHAT_BASE = `${_BASE_ROUTE}/chat`
const _CHAT_DETAIL = `${_CHAT_BASE}/:id`

const _WORDS_ROUTE = `${_BASE_ROUTE}/words`

const _PROGRESS_ROUTE = `${_BASE_ROUTE}/progress`

export type ViewNames =
  | 'login'
  | 'sign-up'
  | 'sign-up:confirm-email'
  | 'sign-up:get-started'
  | 'forgot-password'
  | 'index'
  | 'update-password'
  | 'settings'
  | 'settings:language'
  | 'settings:account'
  | 'settings:subscription'
  | 'settings:integrations'
  | 'chat'
  | 'chat:detail'
  | 'words'
  | 'progress'

const pathRouteMap: { [key in ViewNames]: string } = {
  login: _LOGIN_ROUTE,
  'sign-up': _SIGNUP_ROUTE,
  'sign-up:confirm-email': _SIGNUP_CONFIRM_EMAIL_ROUTE,
  'sign-up:get-started': _SIGNUP_GET_STARTED_ROUTE,
  'forgot-password': _FORGOT_PASSWORD_ROUTE,
  index: _INDEX_ROUTE,
  'update-password': _UPDATE_PASSWORD_ROUTE,
  settings: _SETTINGS_BASE,
  'settings:language': _SETTINGS_LANGUAGE,
  'settings:account': _SETTINGS_ACCOUNT,
  'settings:subscription': _SETTINGS_SUBSCRIPTION,
  'settings:integrations': _SETTINGS_INTEGRATIONS,
  chat: _CHAT_BASE,
  'chat:detail': _CHAT_DETAIL,
  words: _WORDS_ROUTE,
  progress: _PROGRESS_ROUTE,
}

export const processUrl = (
  path: string,
  kwargs?: { [key: string]: string | undefined }
): string => {
  if (!kwargs) {
    return path
  }

  let _processedPath: string = path
  Object.keys(kwargs).forEach((_key) => {
    const _value = kwargs[_key]
    if (_value) {
      _processedPath = _processedPath.replace(`:${_key}`, _value)
    }
  })

  return _processedPath
}

export const reverseUrl = (
  viewName: ViewNames,
  kwargs?: { [key: string]: string | undefined },
  queryParams?: { [key: string]: string }
): string => {
  if (!(viewName in pathRouteMap)) {
    return '/not-found'
  }

  const processedUrl = processUrl(pathRouteMap[viewName], kwargs)

  if (queryParams && Object.keys(queryParams).length > 0) {
    const urlSearchParams = new URLSearchParams(queryParams).toString()
    return `${processedUrl}?${urlSearchParams}`
  }

  return processedUrl
}
