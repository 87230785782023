import { Static, Type } from '@sinclair/typebox'

/**
 * Schema for common createdAt property.
 * Represents the creation timestamp of an entity.
 */
export const CommonCreatedAtSchema = Type.Object(
  {
    createdAt: Type.Unsafe<Date>({ type: 'string', format: 'date-time' }),
  },
  { additionalProperties: false }
)

export type CommonCreatedAtType = Static<typeof CommonCreatedAtSchema>

/**
 * Schema for common read-only properties.
 * Includes the unique identifier and creation timestamp.
 */
export const CommonReadOnlySchema = Type.Intersect(
  [
    Type.Object({ id: Type.String() }, { additionalProperties: false }),
    CommonCreatedAtSchema,
  ],
  { additionalProperties: false }
)

export type CommonReadOnlyType = Static<typeof CommonReadOnlySchema>
