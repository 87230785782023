import { Topics } from '../index'

export const ALL_TOPICS = [
  'restaurant',
  'weather',
  'meeting-someone-new',
  'asking-for-directions',
  'shopping',
  'planning-a-trip',
  'discussing-hobbies',
  'hotel-reservation',
  'cafe',
  'dating',
] as const

export const isTopic = (value: unknown): value is Topics => {
  return ALL_TOPICS.indexOf(value as Topics) != -1
}

export const isString = (value: any): value is string => {
  return typeof value === 'string'
}

export enum NAMESPACES {
  CONVERSATIONS = '/conversations',
}

export const SOCKET_PATH = '/conversations/ws'
