import { Type } from '@sinclair/typebox'

import { CommonReadOnlySchema } from './common'

const CouponCodeSchema = Type.Object({
  code: Type.String(),
  title: Type.String(),
  discountPercentage: Type.Number(),
  expiresAt: Type.Unsafe<Date>({ type: 'string', format: 'date-time' }),
  active: Type.Boolean(),
})

export const ActiveCouponCodesSchema = Type.Intersect(
  [CouponCodeSchema, CommonReadOnlySchema],
  {
    additionalProperties: false,
  }
)
