import { Type } from '@sinclair/typebox'

export const SendEmailSchema = Type.Object(
  {
    from: Type.String({ format: 'email' }),
    to: Type.Array(Type.String({ format: 'email' }), {
      minItems: 1,
    }),
    subject: Type.String({ minLength: 1 }),
    html: Type.String({ minLength: 1 }),
    userId: Type.String({ minLength: 1 }),
  },
  { additionalProperties: false }
)
