// product.ts

import { Static, Type } from '@sinclair/typebox'

import {
  SupabaseCustomerSchema,
  SupabaseCustomerType,
} from './supabaseCustomer'

/**
 * Generic JSON type.
 * Represents arbitrary JSON data.
 */
const JsonSchema = Type.Any()
export type JsonType = Static<typeof JsonSchema>

/**
 * Enum for Pricing Plan Intervals.
 */
const PricingInterval = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
} as const
export const PricingPlanIntervalSchema = Type.Enum(PricingInterval)
export type PricingPlanIntervalType = Static<typeof PricingPlanIntervalSchema>

/**
 * Enum for Pricing Types.
 */
const BillingTypes = {
  one_time: 'one_time',
  recurring: 'recurring',
} as const
export const BillingTypeSchema = Type.Enum(BillingTypes)
export type BillingType = Static<typeof BillingTypeSchema>

/**
 * Schema for creating a Product.
 */
export const ProductCreateSchema = Type.Object(
  {
    id: Type.String(),
    active: Type.Optional(Type.Boolean()),
    name: Type.Optional(Type.String()),
    description: Type.Optional(Type.String()),
    image: Type.Optional(Type.String()),
    metadata: Type.Optional(JsonSchema),
  },
  { additionalProperties: false }
)

export type ProductCreateType = Static<typeof ProductCreateSchema>

/**
 * Complete schema for a Product.
 */
export const ProductSchema = Type.Intersect([ProductCreateSchema], {
  additionalProperties: false,
})

export type ProductType = Static<typeof ProductSchema>

/**
 * Schema for a Price.
 */
export const PriceSchema = Type.Object(
  {
    id: Type.String(),
    productId: Type.Optional(Type.String()),
    active: Type.Optional(Type.Boolean()),
    nickname: Type.Optional(Type.String()),
    unitAmount: Type.Optional(Type.Number()),
    currency: Type.Optional(Type.String()),
    type: Type.Optional(BillingTypeSchema),
    interval: Type.Optional(PricingPlanIntervalSchema),
    intervalCount: Type.Optional(Type.Number()),
    trialPeriodDays: Type.Optional(Type.Number()),
    metadata: Type.Optional(JsonSchema),
  },
  { additionalProperties: false }
)

export type PriceType = Static<typeof PriceSchema>

/**
 * Schema for a Product with its Prices.
 */
export const ProductWithPricesSchema = Type.Record(
  Type.String(),
  Type.Object(
    {
      product: ProductSchema,
      prices: Type.Array(PriceSchema),
    },
    { additionalProperties: false }
  )
)

export type ProductWithPricesType = Static<typeof ProductWithPricesSchema>

/**
 * Enum for Subscription Statuses.
 */
const SubscriptionStatuses = {
  trialing: 'trialing',
  active: 'active',
  canceled: 'canceled',
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  past_due: 'past_due',
  unpaid: 'unpaid',
  paused: 'paused',
} as const

export const SubscriptionStatusSchema = Type.Enum(SubscriptionStatuses)
export type SubscriptionStatusType = Static<typeof SubscriptionStatusSchema>

/**
 * Schema for a Subscription.
 */
export const SubscriptionSchema = Type.Object(
  {
    id: Type.String(),
    userId: Type.String(),
    subscriptionStatus: SubscriptionStatusSchema,
    metadata: Type.Optional(JsonSchema),
    priceId: Type.Optional(Type.String()),
    cancelAtPeriodEnd: Type.Optional(Type.Boolean()),
    created: Type.Optional(Type.String({ format: 'date-time' })),
    currentPeriodStart: Type.String({ format: 'date-time' }),
    currentPeriodEnd: Type.String({ format: 'date-time' }),
    endedAt: Type.Optional(Type.String({ format: 'date-time' })),
    cancelAt: Type.Optional(Type.String({ format: 'date-time' })),
    canceledAt: Type.Optional(Type.String({ format: 'date-time' })),
    trialStart: Type.Optional(Type.String({ format: 'date-time' })),
    trialEnd: Type.Optional(Type.String({ format: 'date-time' })),
  },
  { additionalProperties: false }
)

export type SubscriptionType = Static<typeof SubscriptionSchema>

/**
 * Schema for Supabase Customer with an active Subscription.
 */
export const SupabaseCustomerWithSubscriptionSchema = Type.Intersect(
  [
    SupabaseCustomerSchema,
    Type.Object(
      {
        activeSubscription: Type.Optional(SubscriptionSchema),
      },
      { additionalProperties: false }
    ),
  ],
  { additionalProperties: false }
)

export type SupabaseCustomerWithSubscriptionType = Static<
  typeof SupabaseCustomerWithSubscriptionSchema
>
