import { Static, Type } from '@sinclair/typebox'

import { CommonReadOnlySchema } from './common'
import { LanguageSchema } from './language'
import { ChatMessageSchema } from './message'
import { UserLanguageSettingSchema } from './users'

/**
 * Enum for Conversation Topics.
 */
const Topics = [
  'restaurant',
  'weather',
  'meeting-someone-new',
  'asking-for-directions',
  'shopping',
  'planning-a-trip',
  'discussing-hobbies',
  'hotel-reservation',
  'cafe',
  'dating',
] as const

/**
 * Schema for Conversation Topics with optional null value.
 */
const TopicsWithNull = Type.Union([
  ...Topics.map((topic) => Type.Literal(topic)),
  Type.Null(),
])

/**
 * Schema for creating a Conversation.
 */
export const ConversationCreateSchema = Type.Object(
  {
    title: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    topic: Type.Optional(TopicsWithNull),
  },
  { additionalProperties: false }
)

export type ConversationCreateType = Static<typeof ConversationCreateSchema>

/**
 * Schema for read-only properties of a Conversation.
 */
export const ConversationReadOnlySchema = Type.Object(
  {
    userId: Type.String(),
    ...CommonReadOnlySchema.properties, // Spreads the properties from CommonReadOnlySchema
  },
  { additionalProperties: false }
)

export type ConversationReadOnlyType = Static<typeof ConversationReadOnlySchema>

/**
 * Complete schema for a Conversation.
 */
export const ConversationSchema = Type.Intersect(
  [ConversationCreateSchema, ConversationReadOnlySchema],
  { additionalProperties: false }
)

export type ConversationType = Static<typeof ConversationSchema>

/**
 * Schema for a Conversation with associated Languages.
 */
export const ConversationWithLanguagesSchema = Type.Intersect(
  [
    ConversationCreateSchema,
    ConversationReadOnlySchema,
    Type.Object(
      {
        nativeLanguage: LanguageSchema,
        targetLanguage: LanguageSchema,
      },
      { additionalProperties: false }
    ),
  ],
  { additionalProperties: false }
)

export type ConversationWithLanguagesType = Static<
  typeof ConversationWithLanguagesSchema
>

/**
 * Schema for paginated messages within a Conversation.
 */
export const PaginatedConversationMessagesSchema = Type.Object(
  {
    results: Type.Array(ChatMessageSchema),
    next: Type.Optional(Type.String()),
    previous: Type.Optional(Type.String()),
  },
  { additionalProperties: false }
)

export type PaginatedConversationMessagesType = Static<
  typeof PaginatedConversationMessagesSchema
>

/**
 * Schema for a Conversation with Messages.
 */
export const ConversationWithMessagesSchema = Type.Intersect(
  [
    ConversationCreateSchema,
    ConversationReadOnlySchema,
    PaginatedConversationMessagesSchema,
  ],
  { additionalProperties: false }
)

export type ConversationWithMessagesType = Static<
  typeof ConversationWithMessagesSchema
>

/**
 * Schema for a Conversation with Messages and Languages.
 */
export const ConversationWithMessagesAndLanguagesSchema = Type.Intersect(
  [
    ConversationWithMessagesSchema,
    Type.Object(
      {
        nativeLanguage: LanguageSchema,
        targetLanguage: LanguageSchema,
        userTargetLanguageSettings: UserLanguageSettingSchema,
      },
      { additionalProperties: false }
    ),
  ],
  { additionalProperties: false }
)

export type ConversationWithMessagesAndLanguagesType = Static<
  typeof ConversationWithMessagesAndLanguagesSchema
>
