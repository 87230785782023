import { Static, Type } from '@sinclair/typebox'

/**
 * Schema for Azure Token.
 * Represents the authentication token and related metadata.
 */
export const AzureTokenSchema = Type.Object(
  {
    token: Type.String(),
    region: Type.String(),
    expires: Type.Number(),
  },
  { additionalProperties: false }
)

export type AzureTokenType = Static<typeof AzureTokenSchema>
