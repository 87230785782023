import { Static, Type } from '@sinclair/typebox'

/**
 * Schema for SupabaseCustomer
 */
export const SupabaseCustomerSchema = Type.Object(
  {
    id: Type.String(),
    stripeCustomerId: Type.Union([Type.String(), Type.Null()]),
  },
  { additionalProperties: false }
)

export type SupabaseCustomerType = Static<typeof SupabaseCustomerSchema>
