export enum PosthogEvent {
  SIGNUP_STEP_1 = 'Signup Step 1',
  SIGNUP_STEP_2 = 'Signup Step 2',
  SIGNUP_STEP_3 = 'Signup Step 3',
  SIGNUP_STEP_4 = 'Signup Step 4',
  SIGNUP_COMPLETED = 'Signup Completed',
  STRIPE_CUSTOMER_CREATED = 'Stripe Customer Created',
  STRIPE_CUSTOMER_DELETED = 'Stripe Customer Deleted',
  STRIPE_PORTAL_CREATED = 'Stripe Portal Created',
  STRIPE_CHECKOUT_CREATED = 'Stripe Checkout Created',
  STRIPE_CHECKOUT_SESSION_COMPLETED = 'Stripe Checkout Session Completed',
  STRIPE_SUBSCRIPTION_STATUS_CHANGE = 'Stripe Subscription Status Change',
  USER_MESSAGE_CREATED = 'User Message Created',
  AGENT_MESSAGE_CREATED = 'Agent Message Created',
  CONVERSATION_CREATED = 'Conversation Created',
  AVATAR_CREATED = 'Avatar Created',
  USER_DELETED = 'User Deleted',
}
