import { Static, Type } from '@sinclair/typebox'

import { CommonReadOnlySchema } from './common'

/**
 * Schema for a JMDict Entry.
 */
export const JMDictEntrySchema = Type.Object(
  {
    word: Type.String(),
    reading: Type.String(),
    partOfSpeech: Type.String(),
    inflectionType: Type.String(),
    priority: Type.String(),
    definitions: Type.Array(Type.Array(Type.String())),
  },
  { additionalProperties: false }
)

export type JMDictEntryType = Static<typeof JMDictEntrySchema>

/**
 * Schema for JP Kanji Readings.
 */
export const JPKanjiReadingsSchema = Type.Record(Type.Number(), Type.String())
export type JPKanjiReadingsType = Static<typeof JPKanjiReadingsSchema>

/**
 * Schema for JP Word Entry.
 */
export const JPWordEntrySchema = Type.Object(
  {
    kanjiReadings: JPKanjiReadingsSchema,
    entries: Type.Array(JMDictEntrySchema),
    surface: Type.String(),
  },
  { additionalProperties: false }
)

export type JPWordEntryType = Static<typeof JPWordEntrySchema>

/**
 * Schema for JP Decoration.
 */
export const JPDecorationSchema = Type.Object(
  {
    wordData: Type.Union([JPWordEntrySchema, Type.Null()]),
  },
  { additionalProperties: false }
)

export type JPDecorationType = Static<typeof JPDecorationSchema>

/**
 * Schema for NLP Decoration.
 */
export const NLPDecorationSchema = Type.Optional(
  Type.Object(
    {
      text: Type.String(),
      decorations: Type.Union([Type.Array(JPDecorationSchema), Type.Null()]),
    },
    { additionalProperties: false }
  )
)

export type NLPDecorationType = Static<typeof NLPDecorationSchema>

/**
 * Schema for creating a Message.
 */
export const MessageCreateSchema = Type.Object(
  {
    conversationId: Type.String(),
    userId: Type.Union([Type.String(), Type.Null()]),
    isAgent: Type.Boolean(),
    message: Type.String(),
    isHidden: Type.Boolean(),
  },
  { additionalProperties: false }
)

export type MessageCreateType = Static<typeof MessageCreateSchema>

/**
 * Complete schema for a Message including read-only properties and NLP decorations.
 */
export const ChatMessageSchema = Type.Intersect(
  [MessageCreateSchema, CommonReadOnlySchema, NLPDecorationSchema],
  { additionalProperties: false }
)

export type ChatMessageSchema = Static<typeof ChatMessageSchema>
