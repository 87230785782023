import { Static, Type } from '@sinclair/typebox'

import { CommonReadOnlySchema, CommonReadOnlyType } from './common'

/**
 * Schema for creating a Language.
 */
export const LanguageCreateSchema = Type.Object(
  {
    name: Type.String(),
    languageCode: Type.String(),
  },
  { additionalProperties: false }
)

export type LanguageCreateType = Static<typeof LanguageCreateSchema>

/**
 * Complete schema for a Language including read-only properties.
 */
export const LanguageSchema = Type.Intersect(
  [LanguageCreateSchema, CommonReadOnlySchema],
  { additionalProperties: false }
)

export type LanguageType = Static<typeof LanguageSchema>
