export const MAILCHIMP_AUDIENCE_ID = 'ad591469bc'
export const MAILCHIMP_SERVER = 'us18'

export const MAILCHIMP_TAGS = {
  NEWSLETTER: 'Newsletter',
  ACCOUNT_CREATED: 'Account Created',
  STRIPE_ACTIVE_SUBSCRIBER: 'Stripe Active Subscriber',
  STRIPE_FORMER_SUBSCRIBER: 'Stripe Former Subscriber',
  UNPAID_SUBSCRIPTION: 'Unpaid Subscription',
  TEST_USER: 'TEST USER',
} as const

export type MailchimpTagsType =
  (typeof MAILCHIMP_TAGS)[keyof typeof MAILCHIMP_TAGS]
